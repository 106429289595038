<template>
  <div :class='pageClassByNavigationComponent'
    :style='minHeightStyle'>
    <component
      :is='pageNavigationComponent'
      :sidebar-links='sidebarConfigurations.sidebarLinks'
      :sidebar-page-title='sidebarConfigurations.sidebarPageTitle'
      :custom-background-stype='sidebarConfigurations.customBackgroundStyle' />
    <div class='flex-grow relative px-4 lg:px-0'>
      <welcome-conference-banner v-if='showConferenceBanner' class='mb-8 lg:mb-12' />
      <div v-if='alreadyRegisteredMyself' class='py-8 lg:py-16'>
        <registration-done-message
         class='max-w-2xl bg-gray-100 rounded-md p-16'
         :is-korean='isKorean' />
      </div>
      <div class='py-8 lg:py-16' v-else-if='isRegistrationPeriod'>
        <h1 class='text-3xl font-bold mb-4 lg:mb-8'>{{ registrationFormBoxTitle }}</h1>
        <div v-if='registrationFormBoxHtmlContent' v-html='registrationFormBoxHtmlContent'></div>
        <edit-form-field
          class='mb-4 w-full lg:w-2/3' 
          v-for='(formField,index) in registrationFormFields'
          :key='`${formField.keyName}-${index}`'
          :edit-form-field='formField'
          :show-requirement-indicator-on-top='showRequirementIndicatorOnTop'
          :is-horizontal-layout='isFormFieldsHorizontalLayout'
          :value='fieldValue(formField.category, formField.keyName)'
          :requirement-indicator-helper-text='requirementIndicatorHelperText'
          @update-edit-form-field-value='updateRegistrationFormFieldValue(formField.category, formField.keyName, ...arguments)' />
        <div v-if='!isKvbmMember'
          class='mb-6 w-full lg:w-2/3'>
          <div class='flex flex-col lg:flex-row items-stretch justify-start gap-x-2 gap-y-2'>
            <div class='flex-grow flex flex-row gap-x-2'>
              <label class='uppercase text-sm text-gray-900 w-32 lg:w-60 flex-shrink-0 p-2 bg-gray-50'>Promotional Code</label>
              <div class='flex-grow'>
                <input
                  :readonly='!enteringCouponCode'
                  v-model='couponCode'
                  class='border py-1 px-2 rounded block w-full'
                  :class='couponCodeInputClass'>
              </div>
            </div>
            <div class='flex flex-row gap-x-2'>
              <button class='uppercase border py-1 px-12 rounded block submit-button flex-grow'
                :disabled='!couponCode || !enteringCouponCode'
                :class='couponCodeApplyButtonClass'
                @click='applyRegistrationFeeDiscountCoupon(couponCode)'>
                Apply
              </button>
              <button v-if='!enteringCouponCode'
                @click='resetCouponCode'
                class='uppercase border py-1 px-4 rounded block submit-button'>
                Re-enter code
              </button>
            </div>
          </div>
        </div>
        <div v-if='isKvbmMember'>
          <div class='border rounded-t-md bg-gray-50 p-4 w-full lg:w-2/3 mb-6'>
            한국혈관학회 회원은 해당학회의 웹사이트에서 등록을 하셔야 합니다.
          </div>
          <button 
            class='px-4 py-3 hover:shadow-md uppercase border rounded-md submit-button w-full lg:w-1/2 mb-6'
            @click='goToKvbmSite'>
            한국혈관학회 웹사이트로 이동
          </button>
        </div>
        <div v-else>
          <div class='border rounded-t-md bg-gray-50 p-4 w-full lg:w-2/3 mb-6'>
            <h2 class='text-lg font-semibold mb-4 text-gray-900'>{{ registrationFeeBoxTitle }}</h2>
            <div class='flex flex-row justify-between mb-2 text-gray-900'>
              <h4>Registration Fees</h4><p> {{ formattedFeeAmount(registrationFeeAmount) }}</p>
            </div>
            <div v-if='attendWelcomeReception' class='flex flex-row justify-between mb-2 text-gray-900'>
              <h4>Welcome Reception</h4><p> {{ formattedFeeAmount(welcomeReceptionFeeAmount) }}</p>
            </div>
            <div v-if='hasCurrentCoupon' class='flex flex-row justify-between mb-2 text-gray-900'>
              <h4>Promotion</h4><p>- {{ formattedFeeAmount(registrationFeeDiscountAmount) }}</p>
            </div>
            <div v-if='hasCurrentCoupon || attendWelcomeReception' class='flex flex-row justify-between mb-2 text-gray-900'>
              <h4>Total</h4><p>{{ formattedFeeAmount(totalRegistrationFee) }}</p>
            </div>
            <div class='flex flex-row justify-between mb-2' v-if='paymentGateway === "bank_transfer"'>
              <h4>Bank Name</h4>
              <p class='text-gray-900 rounded font-semibold'>Shinhan Bank</p>
            </div>
            <div class='flex flex-row justify-between mb-2' v-if='paymentGateway === "bank_transfer"'>
              <h4>Account Number</h4>
              <p v-if='isKorean' class='text-gray-900 rounded font-semibold'>100-033-623706</p>
              <p v-else class='text-gray-900 rounded font-semibold'>180-005-911078</p>
            </div>
            <div class='flex flex-row justify-between mb-2' v-if='paymentGateway === "bank_transfer"'>
              <h4>Account Holder</h4>
              <p v-if='isKorean' class='text-gray-900 rounded font-semibold'>한국지질동맥경화학회(The Korean Society of Lipid and Atherosclerosis)</p>
              <p v-else class='text-gray-900 rounded font-semibold'>The Korean Society of Lipid and Atherosclerosis</p>
            </div>
          </div>
          <button 
            class='px-4 py-3 hover:shadow-md uppercase border rounded-md submit-button w-full lg:w-1/2 mb-6'
            @click='registerConference'
            :disabled='disabledRegistrationButton'
            :class='disabledRegistrationButtonClass'>
            {{ registrationButtonText }}
          </button>
        </div>
      </div>
      <div v-else v-html='registrationPeriodOverHtmlContent'></div>
      <welcome-sponsor-carousel  v-if='showSponsorCarousel' class='max-w-4xl mt-16 lg:mt-20'/>
    </div>
    <modal
      name='registration-payment-modal'
      :adaptive='true'
      :click-to-close='canClickToCloseRegistrationModal'
      height='auto'
      :scrollable='true'
      classes='relative'
      @opened='initializePaymentModal'
      @closed='redirectIfFinished'>
      <button v-if='canClickToCloseRegistrationModal'
        class='z-30 absolute top-3 right-3' 
        @click="$modal.hide('registration-payment-modal')">
        <x-icon class='text-black' />
      </button>
      <!-- make component by event? -->
      <div v-if='registrationPaymentModalComponent == "bank-transfer"'>
        <div v-if='isKorean' class='p-8 lg:p-16 overflow-y-auto flex-grow'>
          <div class='max-w-2xl'>
            <h1 class='text-2xl font-semibold mb-8'>ICoLA2023에 등록해 주셔서 감사합니다</h1>
            <div class='px-12 py-8 rounded' style='background-color: #FAFAFA;'>
              <ul class='list-disc'>
                <li class='py-1'>등록비: {{ formattedFeeAmount(totalRegistrationFee) }}</li>
                <li class='py-1'>아래의 계좌로 입금하여 주십시오.</li>
                <li class='py-1'>입금자와 등록자의 성함이 다를 경우, 등록 확인이 불가하니 <span class='font-semibold text-red-600'>반드시 등록자의 성함으로 입금 부탁드립니다.</span></li>
                <li class='py-1'>등록 기간 내 미입금 시 등록이 취소될 수 있음을 안내해 드립니다.</li>
                <li class='py-1'><span class='font-semibold text-red-600'>입금완료 및 등록 완료 확인까지 시일이 소요</span>될 수 있으며, 확인 시 등록완료 메일을 받게 됩니다.</li>
                <li class='py-1'>계좌 정보: <span class='inline-block p-2 bg-gray-200 text-gray-900 rounded font-semibold'>신한은행 100-033-623706</span>
                <li class='py-1'>예금주: 한국지질동맥경화학회(The Korean Society of Lipid and Atherosclerosis)</li>
              </ul>
            </div>
            <router-link :to='{name: "Home"}' class='block mt-8 w-full lg:w-48 text-center bg-gray-900 text-white py-3 px-2 rounded-md hover:shadow-lg text-sm'>홈으로 가기</router-link>
          </div>
        </div>
        <div v-else class='p-8 lg:p-16 overflow-y-auto flex-grow'>
          <div class='max-w-2xl'>
            <h1 class='text-2xl font-semibold mb-8'>Thank you for registering ICoLA2023</h1>
            <div class='px-12 py-8 rounded' style='background-color: #FAFAFA;'>
              <ul class='list-disc'>
                <li class='py-1'>Registration Fee: {{ formattedFeeAmount(totalRegistrationFee) }}</li>
                <li class='py-1'>Please transfer the amount to the bank account below.</li>
                <li class='py-1'><span class='font-semibold text-red-600'>The registered name and depositor name must match. </span>Otherwise, we will be unable to confirm your payment.</li>
                <li class='py-1'>If your payment is not confirmed within the early registration period, your registration will be cancelled.</li>
                <li class='py-1'><span class='font-semibold text-red-600'>Please allow for a few days to confirm the deposit. </span>You will recieve the confirmation email.</li>
                <li class='py-1'>Bank Name / Account Number: <span class='inline-block p-2 bg-gray-200 text-gray-900 rounded font-semibold'>Shinhan Bank 180-005-911078</span>
                <li class='py-1'>Account Holder: The Korean Society of Lipid and Atherosclerosis</li>
              </ul>
            </div>
            <router-link :to='{name: "Home"}' class='uppercase block mt-8 w-full lg:w-48 text-center bg-gray-900 text-white py-3 px-2 rounded-md hover:shadow-lg text-sm'>Go to home</router-link>
          </div>
        </div>
      </div>
      <!--  -->
      <nhnkcp-payment v-else-if='registrationPaymentModalComponent == "nhnkcp-payment"'
        :payment-params-prop='paymentParams'
        :is-modal-payment='true'
        :is-single-order='true'
        :is-korean='isKorean'
        @payment-success='paymentSuccess'
        @payment-fail='paymentFail' />
      <iamport-payment v-else-if='registrationPaymentModalComponent == "iamport-payment"'
        :payment-params-prop='paymentParams'
        :is-modal-payment='true'
        :is-single-order='true'
        :is-korean='isKorean'
        @payment-success='paymentSuccess'
        @payment-fail='paymentFail' />
      <payment-fail v-else-if='registrationPaymentModalComponent == "payment-fail"'
        :is-modal-payment='true'
        :is-korean='isKorean' />
      <payment-success v-else-if='registrationPaymentModalComponent == "payment-success"'
        :payment-params-prop='paymentSuccessParams'
        :is-modal-payment='true'
        :is-korean='isKorean'
        @payment-finished='paymentFinished'
        @payment-fail='paymentFail' />
      <registration-done-message v-else-if='registrationPaymentModalComponent == "registration-done-message"'
        class='max-w-2xl bg-gray-100 rounded-md p-16' 
        :is-korean='isKorean' />
    </modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { XIcon } from '@vue-hero-icons/outline'
import numbro    from 'numbro'
import cloneDeep from 'lodash/cloneDeep'
import camelCase from 'lodash/camelCase'
import jsonLogic from 'json-logic-js'
import { mapFields } from 'vuex-map-fields'
import dayjs from 'dayjs'

export default {
  name: 'Registration',
  components: {
    XIcon,
    'tabs-page-navigation': () => import('@/components/common/TabsPageNavigation'),
    'sidebar-page-navigation': () => import('@/components/common/SidebarPageNavigation'),
    'registration-done-message': () => import('@/components/iamport-payment/RegistrationDoneMessage.vue'), 
    'edit-form-field': () => import('@/components/edit-form/EditFormField.vue'), 
    'welcome-conference-banner': () => import('@/components/welcome/WelcomeConferenceBanner.vue'),
    'welcome-sponsor-carousel': () => import('@/components/welcome/WelcomeSponsorsCarousel.vue'),
    'iamport-payment' : () => import( '@/components/iamport-payment/IamportPayment.vue'),
    'nhnkcp-payment' : () => import( '@/components/nhnkcp-payment/NhnkcpPayment.vue'),
    'payment-fail' : () => import( '@/components/payments/PaymentFail.vue'),
    'payment-success' : () => import( '@/components/payments/PaymentSuccess.vue'),
  },
  props: {
    showConferenceBanner: {
      type: Boolean,
      default: false,
    },
    showSponsorCarousel: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      validPhoneNumbers: [],
      couponCode: '',
      enteringCouponCode: true,
      paymentParams: null,
      registrationPaymentModalComponent: '',
      paymentSuccessParams: null,
      paymentFailParams: null,
    }
  },
  watch: {
    'profileDefault': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.profile = cloneDeep(newVal)
          this.getProfile().then((resp) => {
            Object.keys(this.profile).forEach(key => {
              if (key === 'extraInfo') {
                let dieteLicenseNumber = this.profile[key].dieteLicenseNumber ? this.profile[key].dieteLicenseNumber : ''
                let degree = this.profile[key].degree ? this.profile[key].degree : []
                let hospitalType = this.profile[key].hospitalType ? this.profile[key].hospitalType : ''
                let medicalProfession = this.profile[key].medicalProfession ? this.profile[key].medicalProfession : ''
                let specialistLicenseNumber = this.profile[key].specialistLicenseNumber ? this.profile[key].specialistLicenseNumber : ''
                let conferenceFactor = this.profile[key].conferenceFactor ? this.profile[key].conferenceFactor : ''
                this.profile[key] = {
                  'degree': degree,
                  'hospitalType': hospitalType,
                  'conferenceFactor': conferenceFactor,
                  'medicalProfession': medicalProfession,
                  'dieteLicenseNumber': dieteLicenseNumber,
                  'specialistLicenseNumber': specialistLicenseNumber
                }
              } else if (key === 'phoneNumber') {
                let index = this.validPhoneNumbers.findIndex(phoneNumber => phoneNumber.keyName === 'phone_number')
                if (index > -1) {
                  this.validPhoneNumbers[index].phoneNumber = resp[key]
                  this.validPhoneNumbers[index].isValidPhoneNumber = true
                } else {
                  this.validPhoneNumbers.push({
                    keyName: 'phone_number',
                    phoneNumber: resp[key],
                    isValidPhoneNumber: true
                  })
                }
                this.profile[key] = resp[key]
              } else {
                this.profile[key] = resp[key]
              }
            })
          })
        }
      },
      immediate: true,
      deep: true
    },
    'registrationDataDefault': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.registrationData = cloneDeep(newVal)
          this.getRegistrationData().then((resp) => {
            Object.keys(this.registrationData).forEach(key => {
              this.registrationData[key] = resp[key]
            })
            if (this.isPaymentPending) {
              this.openRegistrationPaymentModal()
            } 
          })
        }
      },
      immediate: true,
      deep: true
    },
    'membershipDataDefault': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.membershipData = cloneDeep(newVal)
          this.getMembershipData().then((resp) => {
            Object.keys(this.membershipData).forEach(key => {
              this.membershipData[key] = resp[key]
            })
          })
        }
      },
      immediate: true,
      deep: true
    },
  },
  computed: {
    ...mapFields('users', [
      'signupParams',
      'profile',
      'registrationData',
      'membershipData',
    ]),
    ...mapState('users', [
      'hasValidToken',
    ]),
    ...mapState('products', [
      'products',
    ]),
    ...mapState('registrationFeeDiscountCoupons', [
      'currentCoupon'
    ]),
    ...mapGetters('registrationFeeDiscountCoupons', [
      'hasCurrentCoupon',
    ]),
    ...mapGetters('events', [
      'showingEventId',
      'showingEventRegistrationConfigurations',
      'themeTextColorStyle',
    ]),
    pageNavigationComponent () {
      return (this.showingEventRegistrationConfigurations && this.showingEventRegistrationConfigurations.pageNavigationComponent) ? this.showingEventRegistrationConfigurations.pageNavigationComponent : ''
    },
    pageClassByNavigationComponent () {
      return this.pageNavigationComponent === 'sidebar-page-navigation' ? 'flex flex-col lg:flex-row justify-between items-stretch gap-x-16' : ''
    },
    minHeightStyle () {
      let minHeight = 64 // size of the navbar
      return `min-height: calc(100vh - ${minHeight}px);`
    },
    sidebarConfigurations () {
      return (this.showingEventRegistrationConfigurations && this.showingEventRegistrationConfigurations.sidebarConfigurations) ? this.showingEventRegistrationConfigurations.sidebarConfigurations : {}
    },
    isFormFieldsHorizontalLayout () {
      return (this.showingEventRegistrationConfigurations && this.showingEventRegistrationConfigurations.isFormFieldsHorizontalLayout) ? this.showingEventRegistrationConfigurations.isFormFieldsHorizontalLayout : false
    },
    showRequirementIndicatorOnTop () {
      return (this.showingEventRegistrationConfigurations && this.showingEventRegistrationConfigurations.showRequirementIndicatorOnTop) ? this.showingEventRegistrationConfigurations.showRequirementIndicatorOnTop : false
    },
    requirementIndicatorHelperText () {
      return (this.showingEventRegistrationConfigurations && this.showingEventRegistrationConfigurations.requirementIndicatorHelperText) ? this.showingEventRegistrationConfigurations.requirementIndicatorHelperText : 'is required.'
    },
    isKorean () {
      return !this.profile.country ||
             this.profile.country.toLowerCase() === 'kr'
    },
    registrationFormBoxTitle () {
      return (this.showingEventRegistrationConfigurations && this.showingEventRegistrationConfigurations.registrationFormBoxTitle) ? this.showingEventRegistrationConfigurations.registrationFormBoxTitle : 'My Registration Details'
    },
    registrationFormBoxHtmlContent () {
      return (this.showingEventRegistrationConfigurations && this.showingEventRegistrationConfigurations.registrationFormBoxHtmlContent) ? this.showingEventRegistrationConfigurations.registrationFormBoxHtmlContent : ''
    },
    registrationFeeBoxTitle () {
      return (this.showingEventRegistrationConfigurations && this.showingEventRegistrationConfigurations.registrationFeeBoxTitle) ? this.showingEventRegistrationConfigurations.registrationFeeBoxTitle : 'Registration Fees'
    },
    registrationButtonText () {
      return (this.showingEventRegistrationConfigurations && this.showingEventRegistrationConfigurations.registrationButtonText) ? this.showingEventRegistrationConfigurations.registrationButtonText : 'Register & Pay Registration Fees'
    },
    profileDefault () {
      return (this.showingEventRegistrationConfigurations && this.showingEventRegistrationConfigurations.profileDefault) ? this.showingEventRegistrationConfigurations.profileDefault : {}
    },
    registrationDataDefault () {
      return (this.showingEventRegistrationConfigurations && this.showingEventRegistrationConfigurations.registrationDataDefault) ? this.showingEventRegistrationConfigurations.registrationDataDefault : {}
    },
    membershipDataDefault () {
      return (this.showingEventRegistrationConfigurations && this.showingEventRegistrationConfigurations.membershipDataDefault) ? this.showingEventRegistrationConfigurations.membershipDataDefault : {}
    },
    registrationDeadline () {
      return (this.showingEventRegistrationConfigurations && this.showingEventRegistrationConfigurations.registrationDeadline) ? this.showingEventRegistrationConfigurations.registrationDeadline : ''
    },
    earlyRegistrationDeadline () {
      return '2023-07-24T15:00:00+00:00'
    },
    registrationPeriodOverHtmlContent () {
      return (this.showingEventRegistrationConfigurations && this.showingEventRegistrationConfigurations.registrationPeriodOverHtmlContent) ? this.showingEventRegistrationConfigurations.registrationPeriodOverHtmlContent : '등록기간이 아닙니다.'
    },
    isEalryRegistrationPeriod () {
      return dayjs(this.earlyRegistrationDeadline).isAfter(dayjs(new Date())) && this.earlyRegistrationDeadline
    },
    isRegistrationPeriod () {
      return !this.registrationDeadline ||
             (dayjs(this.registrationDeadline).isAfter(dayjs(new Date())) && this.registrationDeadline)
    },
    currentDateTimeString () {
      return dayjs().format('YYYYMMDDHHmm')
    },
    registrationFormFields () {
      return (this.showingEventRegistrationConfigurations && this.showingEventRegistrationConfigurations.registrationFormFields) ?
        this.showingEventRegistrationConfigurations.registrationFormFields.concat()
        .filter((field) => {
          let condition = field.metaData.showCondition ? field.metaData.showCondition : true
          return jsonLogic.apply(condition, {registrationData: this.registrationData, profile: this.profile, membershipData: this.membershipData, currentDateTime: this.currentDateTimeString})
        })
        .sort((fieldA, fieldB) => fieldA.sortOrder - fieldB.sortOrder) : []
    },
    isKSoLAMember () {
      return this.membershipData ? this.membershipData.isIcolaMember === '한국지질동맥경화학회' : false
    },
    isKvbmMember () {
      return this.membershipData ? this.membershipData.isIcolaMember === '한국혈관학회' : false
    },
    isProfessor () {
      return ['Professor', 'Specialist'].map(item => item.toLowerCase()).includes(this.medicalProfession.toLowerCase())
    },
    attendWelcomeReception () {
      return this.registrationData ? this.registrationData.welcomeReception === 'Attend' : false
    },
    medicalProfession () {
      return this.profile && this.profile.extraInfo ? this.profile.extraInfo.medicalProfession : ''
    },
    paymentGateway () {
      return this.registrationData ? this.registrationData.paymentGateway : ''
    },
    alreadyRegisteredMyself () {
      return this.registrationData.payment
    },
    registrationFeeAmount () {
      return this.registrationProduct ? this.registrationProduct.price.amount : 0
    },
    registrationFeeUnit () {
      return this.registrationProduct ? this.registrationProduct.price.unit : ''
    },
    welcomeReceptionFeeAmount () {
      if (this.attendWelcomeReception) {
        if (this.isKorean) {
          return 10000
        } else {
          return 10
        }
      } else {
        return 0
      }
    },
    registrationFeeDiscountAmount () {
      return this.hasCurrentCoupon ? (parseFloat(this.welcomeReceptionFeeAmount) + parseFloat(this.registrationFeeAmount)) * parseFloat(this.currentCoupon.discountRate) / 100 : 0
    },
    totalRegistrationFee () {
      return (parseFloat(this.welcomeReceptionFeeAmount) + parseFloat(this.registrationFeeAmount)) - this.registrationFeeDiscountAmount
    },
    registrationProduct () {
      if (this.isEalryRegistrationPeriod) {
        if (this.isKorean) {
          if (this.isProfessor) {
            if (this.isKSoLAMember) {
              return this.products.find(product => product.name === 'icola-early-domestic-professor-member-80000KRW')
            } else {
              return this.products.find(product => product.name === 'icola-early-domestic-professor-nonmember-100000KRW')
            }
          } else {
            return this.products.find(product => product.name === 'icola-early-domestic-others-50000KRW')
          }
        } else {
          if (this.isProfessor) {
            return this.products.find(product => product.name === 'icola-early-overseas-professor-200USD')
          } else {
            return this.products.find(product => product.name === 'icola-early-overseas-others-100USD')
          }
        }
      } else {
        if (this.isKorean) {
          if (this.isProfessor) {
            if (this.isKSoLAMember) {
              return this.products.find(product => product.name === 'icola-pre-domestic-professor-member-100000KRW')
            } else {
              return this.products.find(product => product.name === 'icola-pre-domestic-professor-nonmember-120000KRW')
            }
          } else {
            return this.products.find(product => product.name === 'icola-pre-domestic-others-60000KRW')
          }
        } else {
          if (this.isProfessor) {
            return this.products.find(product => product.name === 'icola-pre-overseas-professor-300USD')
          } else {
            return this.products.find(product => product.name === 'icola-pre-overseas-others-150USD')
          }
        }
      }
    },
    disabledRegistrationButton () {
      return this.registrationFormFields.map(field => this.fieldValue(field.category, field.keyName)).some(value => !value ||
        typeof value === 'string' && !value ||
        typeof value === 'object' && !Object.keys(value).length ||
        Array.isArray(value) && !value.length)
    },
    disabledRegistrationButtonClass () {
      return this.disabledRegistrationButton ? 'opacity-50 cursor-not-allowed' : ''
    },
    canClickToCloseRegistrationModal () {
      return this.registrationPaymentModalComponent == 'payment-fail' || this.isPaymentPending
    },
    couponCodeApplyButtonClass () {
      return !this.couponCode || !this.enteringCouponCode ? 'opacity-50 cursor-not-allowed' : 'opacity-100 cursor-pointer'
    },
    couponCodeInputClass () {
      return this.enteringCouponCode ? '' : 'opacity-50 cursor-not-allowed'
    },
    isPaymentPending () {
      return !this.registrationData.payment &&
      this.registrationData.paymentGateway === 'bank_transfer'
    },
  },
  methods: {
    ...mapActions('users', [
      'getProfile',
      'getMembershipData',
      'getRegistrationData',
      'patchProfile',
      'patchMembershipData',
      'patchRegistrationData',
      'checkTokenStatus',
    ]),
    ...mapActions('products', [
      'getAllProducts',
    ]),
    ...mapActions('orders', [
      'createOrder',
    ]),
    ...mapActions('registrations', [
      'sendRegistrationEmails'
    ]),
    ...mapActions('registrationFeeDiscountCoupons', [
      'getSingleCouponByCode',
      'resetCurrentCoupon',
      'useCouponByCode',
    ]),
    applyRegistrationFeeDiscountCoupon () {
      this.getSingleCouponByCode(this.couponCode).then(() => {
        this.enteringCouponCode = false
          this.$alert('The promotional code has been applied.', {
            confirmButtonText: 'OK',
            type: 'success'
          })
      }).catch((error) => {
        this.couponCode = ''
        if (error.response.data.error === 'UnavailableRegistrationFeeDiscountCoupon') {
          this.$alert('The promotional code you have entered is invalid. Please check the code and try again.', {
            confirmButtonText: 'OK',
            type: 'warning'
          })
        }
      })
    },
    resetCouponCode () {
      this.resetCurrentCoupon().then(() => {
        this.couponCode = ''
        this.enteringCouponCode = true
      })
    },
    fieldValue (category, keyName) {
      let arr = keyName.split('.').map(keyName => camelCase(keyName))
      let val = ''
      if (category === 'profile') {
        if (arr.length === 2) {
          if (keyName.includes('phone_number')) { 
            let index = this.validPhoneNumbers.findIndex(phoneNumber => phoneNumber.keyName === keyName)
            val = index > -1 && this.profile[arr[0]][arr[1]] ? {
              phoneNumber: this.validPhoneNumbers[index].phoneNumber,
              isValidPhoneNumber: this.validPhoneNumbers[index].isValidPhoneNumber
            } : null
          } else {
            val = this.profile[arr[0]][arr[1]]
          }
        } else if (arr.length === 1) {
          if (keyName === 'address') {
            val = this.profile.address && this.profile.postalCode ? {
              address: this.profile.address,
              postalCode: this.profile.postalCode,
            } : null
          } else if (keyName === 'phone_number') {
            let index = this.validPhoneNumbers.findIndex(phoneNumber => phoneNumber.keyName === 'phone_number')
            val = index > -1 && this.profile[arr[0]] ? {
                phoneNumber: this.validPhoneNumbers[index].phoneNumber,
                isValidPhoneNumber: this.validPhoneNumbers[index].isValidPhoneNumber
            } : null
          } else if (keyName === 'organization_hospital_name') {
            val = this.profile.organization && this.profile.hospitalName ? {
              organization: this.profile.organization,
              hospitalName: this.profile.hospitalName,
            } : null
          } else {
            val = this.profile[arr[0]]
          }
        }
      } else if (category === 'user') {
        if (arr.length === 2) {
          val = this.signupParams[arr[0]][arr[1]]
        } else if (arr.length === 1) {
          val = this.signupParams[arr[0]]
        }
      } else if (category === 'registration_data') {
        if (arr.length === 2) {
          val = this.registrationData[arr[0]][arr[1]]
        } else if (arr.length === 1) {
          val = this.registrationData[arr[0]]
        }
      } else if (category === 'membership_data') {
        if (arr.length === 2) {
          val = this.membershipData[arr[0]][arr[1]]
        } else if (arr.length === 1) {
          val = this.membershipData[arr[0]]
        }
      }
      return val
    },
    updateRegistrationFormFieldValue (category, keyName, value) {
      let arr = keyName.split('.').map(keyName => camelCase(keyName))
      if (category === 'profile') {
        if (arr.length === 2) { //extra info
          if (keyName.includes('phone_number')) { 
            this.profile[arr[0]][arr[1]] = value.phoneNumber
            let index = this.validPhoneNumbers.findIndex(phoneNumber => phoneNumber.keyName === keyName)
            if (index > -1) {
              this.validPhoneNumbers[index].phoneNumber = value.phoneNumber
              this.validPhoneNumbers[index].isValidPhoneNumber = value.isValidPhoneNumber
            } else {
              this.validPhoneNumbers.push({
                keyName: keyName,
                phoneNumber: value.phoneNumber,
                isValidPhoneNumber: value.isValidPhoneNumber
              })
            }
          } else {
            this.profile[arr[0]][arr[1]] = value
          }
        } else if (arr.length === 1) {
          if (keyName === 'phone_number') {
            this.profile.phoneNumber = value.phoneNumber
            let index = this.validPhoneNumbers.findIndex(phoneNumber => phoneNumber.keyName === keyName)
            if (index > -1) {
              this.validPhoneNumbers[index].phoneNumber = value.phoneNumber
              this.validPhoneNumbers[index].isValidPhoneNumber = value.isValidPhoneNumber
            } else {
              this.validPhoneNumbers.push({
                keyName: keyName,
                phoneNumber: value.phoneNumber,
                isValidPhoneNumber: value.isValidPhoneNumber
              })
            }
          } else if (keyName === 'address') {
            this.profile.address = value.address
            this.profile.postalCode = value.postalCode
          } else if (keyName === 'organization_hospital_name') {
            this.profile.organization = value.organization
            this.profile.hospitalName = value.hospitalName
          } else {
            this.profile[arr[0]] = value
          }
        }
      } else if (category === 'user') {
        if (arr.length === 2) {
          this.signupParams[arr[0]][arr[1]] = value
        } else if (arr.length === 1) {
          this.signupParams[arr[0]] = value
        }
      } else if (category === 'registration_data') {
        if (arr.length === 2) {
          this.registrationData[arr[0]][arr[1]] = value
        } else if (arr.length === 1) {
          this.registrationData[arr[0]] = value
        }
      } else if (category === 'membership_data') {
        if (arr.length === 2) {
          this.membershipData[arr[0]][arr[1]] = value
        } else if (arr.length === 1) {
          this.membershipData[arr[0]] = value
        }
      }
    },
    formattedFeeAmount (feeAmount) {
      return feeAmount ? `${numbro(feeAmount).format({
        thousandSeparated: true,
        mantissa: 0,
      })} ${this.registrationFeeUnit}` : 'Free'
    },
    updateUserPaidData () {
      this.patchRegistrationData({ payment: true, paidAt: new Date() }).then(() => {
        this.sendRegistrationEmails({ email_type: 'confirm' })
      })
    },
    updateUserRegistrationFeeDiscountCouponData () {
      this.patchRegistrationData({ payment: true, paidAt: new Date(), registrationFeeDiscountCouponCode: this.currentCoupon.code }).then(() => {
        this.sendRegistrationEmails({ email_type: 'confirm' })
      })
    },
    registerConference () {
      this.registrationData.registrationFee = this.formattedFeeAmount(this.totalRegistrationFee)
      this.registrationData.registrationType = this.registrationProduct.name
      let patchProfile = this.patchProfile(this.profile)
      let patchMembershipData = this.patchMembershipData(this.membershipData)
      let patchRegistrationData = this.patchRegistrationData(this.registrationData)

      Promise.all([patchProfile, patchMembershipData, patchRegistrationData]).then(() => {
        this.createOrder({product: {
          order_amount: this.totalRegistrationFee,
          product_id: this.registrationProduct.id,
          payment_type: this.paymentGateway,
          event_id: this.showingEventId}
        }).then((order) => {
          if (order.status === 'Done') {
            if (this.hasCurrentCoupon) {
              this.updateUserRegistrationFeeDiscountCouponData()
              this.useCouponByCode()
            } else {
              this.updateUserPaidData()
            }
          } else {
            this.paymentParams = {
              pg:           this.paymentGateway,
              currency:     this.registrationProduct.price.priceUnit,
              amount:       order.price.amount,
              orderId:      order.hashedOrderId,
              orderName:    order.orderName,
              customerName: order.customerName ,
              customerPhoneNumber: this.profile.phoneNumber,
            }
            this.openRegistrationPaymentModal()
            if (this.paymentGateway === 'bank_transfer') {
              this.sendRegistrationEmails({ email_type: 'myself_pending' })
            }
          }
        }).catch(() => {
          this.$alert('에러가 발생했습니다. 지속 발생 시, 고객센터로 문의 부탁드립니다.', {
            type: 'warning'
          })
        })
      }).catch((error) => {
        console.error(error)
        this.$alert('에러가 발생했습니다. 지속 발생 시, 고객센터로 문의 부탁드립니다.', {
          type: 'warning'
        })
      })
    },
    initializePaymentModal () {
      if (this.paymentGateway === 'bank_transfer') {
        this.registrationPaymentModalComponent = 'bank-transfer'
      } else if (this.paymentGateway.includes('iamport')) {
        this.registrationPaymentModalComponent = 'iamport-payment'
      } else if (this.paymentGateway.includes('nhnkcp')) {
        this.registrationPaymentModalComponent = 'nhnkcp-payment'
      }
    },
    paymentFail (params) {
      // show payment fail with params
      this.paymentFailParams = params
      this.registrationPaymentModalComponent = 'payment-fail'
    },
    paymentSuccess (params) {
      // show payment success with params
      this.paymentSuccessParams = params
      this.registrationPaymentModalComponent = 'payment-success'
    },
    paymentFinished () {
      // show registration done message
      this.registrationPaymentModalComponent = 'registration-done-message'
      // payment done
      this.getRegistrationData().then(() => {
        if (!this.alreadyRegisteredMyself) {
          this.updateUserPaidData()
        }
      })
    },
    redirectIfFinished () {
      if (this.registrationPaymentModalComponent == 'registration-done-message') {
        this.$router.push({name: 'Main'})
      }
    },
    openRegistrationPaymentModal () {
      this.$modal.show('registration-payment-modal')
    },
    closeRegistrationPaymentModal () {
      this.$modal.hide('registration-payment-modal')
    },
    goToKvbmSite () {
      this.patchMembershipData(this.membershipData).then(() => {
        window.open('https://www.kvbm.org/html/?pmode=acingview&smode=view&MMC_pid=24&seq=17', '_blank')
      })
    },
  },
  created () {
    this.checkTokenStatus().then(() => {
      if (!this.hasValidToken) {
        this.$router.replace({
          name: 'Login', 
          query: {
            redirect_route: 'Registration'
          }
        }).catch(() => {})
      }
    })
  },
  mounted () {
    this.getAllProducts()
  },
  beforeDestroy () {
    this.resetCurrentCoupon()
    this.closeRegistrationPaymentModal()
  },
}
</script>

<style type='scss' scoped>
.submit-button {
  @apply text-white;
  background-color: var(--eventMainColor);
}

.background-style {
  background: transparent; 
  width: 100%;
}

.sidebar-navigation-links .router-link-active {
  @apply font-bold border rounded-md;
}
    
@media (min-width: 1024px) {
  .background-style {
    width: 20rem;
    height: auto;
  }
}
</style>
su